.home-container {
    max-width: 1700px;
    margin: 2rem auto;
    text-align: center;
    padding: 1rem;
}

/* Hero Section */
.hero-section {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.hero-overlay {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 8px;
}

.hero-overlay h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.hero-overlay p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.hero-overlay .btn {
    display: inline-block;
    background: #4caf50;
    color: white;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 6px;
    font-weight: bold;
    transition: background 0.3s ease;
}

.hero-overlay .btn:hover {
    background: #45a049;
}

/* About Section */
.about-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 3rem 5%;
    text-align: left;
    background-color: #f9f9f9;
}

/* Responsive Layout for Mobile */
@media (max-width: 768px) {
    .about-section {
        flex-direction: column;
        text-align: center;
    }

    .about-text {
        max-width: 100%;
    }

    .about-video {
        max-width: 100%;
    }
}

/* About Text */
.about-text {
    flex: 1;
    max-width: 50%;
}

.about-text h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.about-text p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1rem;
}

.about-text .btn {
    display: inline-block;
    background: #4caf50;
    color: white;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 6px;
    font-weight: bold;
    transition: background 0.3s ease;
}

.about-text .btn:hover {
    background: #45a049;
}

/* About Video */
.about-video {
    flex: 1;
    max-width: 50%;
    overflow: hidden;
}

.about-preview-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
