.edit-survey {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .edit-survey h2 {
    font-size: 2rem;
    color: #4caf50;
    margin-bottom: 20px;
  }
  
  .survey-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
  }
  
  .text-input,
  .text-area {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .text-area {
    resize: none;
    height: 80px;
  }
  
  .question-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .add-question-button,
  .save-survey-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .add-question-button:hover,
  .save-survey-button:hover {
    background-color: #45a049;
  }
  
  .save-survey-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  