.response-survey-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .response-survey-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .response-survey-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .response-survey-card h3 {
    font-size: 1.5rem;
    color: #4caf50;
    margin-bottom: 0.5rem;
  }
  
  .response-survey-card p {
    font-size: 1rem;
    color: #666;
  }
  