/* Header Styles */
.header {
  background-color: #363d44; /* Grey */
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1100; /* Ensures header stays above everything */
}

/* Header Container */
.header-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo */
.navbar-left {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.header-logo {
  width: 150px;
  cursor: pointer;
}

/* Navigation Links - Centered */
.nav-links {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Center links */
}

.nav-links ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
}

.nav-links ul li {
  display: inline;
}

.nav-links ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links ul li a:hover {
  color: #4caf50;
}

/* Logout Button */
.logout-button {
  background-color: #f44336; /* Red for logout */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/* Mobile Menu Icon */
.hamburger-menu {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

/* Mobile Menu Styles */
@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: block;
    z-index: 1300; /* Ensures it's clickable */
  }

  /* Hide Desktop Nav */
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #363d44;
    padding: 10px 0;
    text-align: center;
    flex-direction: column;
    z-index: 1200; /* Ensures menu is on top */
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  /* Overlay when menu is open */
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1190; /* Just below the menu */
    display: none;
  }

  .nav-links.open ~ .menu-overlay {
    display: block;
  }
}
