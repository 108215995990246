.survey-builder {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.survey-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.text-input,
.text-area {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.question-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.question-form {
  flex: 1 1 calc(50% - 20px);
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.add-question-button,
.save-survey-button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.add-question-button:hover,
.save-survey-button:hover {
  background-color: #45a049;
}
