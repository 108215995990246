@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set Roboto as the default font */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5; /* Optional: Set a global background color */
  color: #333; /* Optional: Set a default text color */
  line-height: 1.6; /* Optional: Improve readability */
}

/* Push Content Below Fixed Header */
.main-content {
  margin-top: 180px; /* Adjust to match the header height */
}

@media screen and (max-width: 768px) {
  .main-content {
    margin-top: 180px; /* More space for mobile menu */
  }
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500; /* Slightly bolder for headings */
}

/* Text Inputs */
button, input, textarea {
  font-family: 'Roboto', sans-serif;
}

/* Links */
a {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: #007bff; /* Optional: Default link color */
}

a:hover {
  text-decoration: underline;
}

/* Centered Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
