.question-form {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.question-header {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.question-input {
  flex: 3;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.question-type-select {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.options-container {
  margin-top: 15px;
}

.options-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.option-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.option-input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-option-button,
.remove-option-button {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-option-button:hover,
.remove-option-button:hover {
  background-color: #45a049;
}

.required-toggle {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.required-toggle label {
  font-size: 1rem;
  color: #333;
}

.remove-question-button {
  margin-top: 20px;
  background-color: #ff5722;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-question-button:hover {
  background-color: #e64a19;
}
