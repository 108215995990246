.survey-form {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.survey-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.survey-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

.question-container {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  text-align: left;
  position: relative;
}

.question-container.error {
  border: 2px solid red; /* Highlight incomplete questions */
  background: #ffe5e5;
}

.question-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}

.required-asterisk {
  color: red;
  margin-left: 5px;
}

.option-label {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;
  width: 100%;
}

.option-label input {
  margin: 0;
}

.text-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.other-option {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.other-option label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.other-textbox {
  margin-top: 5px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.rating-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-size: 1rem;
  margin-top: 5px;
  font-weight: bold;
}

.question-divider {
  border: none;
  height: 1px;
  background: #ddd;
  margin: 20px 0;
  width: 100%;
}

.submit-button {
  padding: 12px 25px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  transition: background-color 0.2s ease-in-out;
}

.submit-button:hover {
  background-color: #45a049;
}
