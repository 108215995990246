.contact-container {
  text-align: center;
  max-width: 800px;
  margin: auto;
  padding: 2rem 1rem;
}

/* Hero Video Section */
.hero-video-section {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* Contact Info */
.contact-info {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.contact-info h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.contact-info p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: auto;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: auto;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-form textarea {
  height: 120px;
  resize: none;
}

.contact-form button {
  background: #4caf50;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.contact-form button:hover {
  background: #45a049;
}

/* Status Message */
.status-message {
  margin-top: 1rem;
  font-size: 1rem;
  color: #4caf50;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-video-section {
      height: 300px;
  }

  .contact-form {
      width: 90%;
      padding: 1.5rem;
  }

  .contact-info h1 {
      font-size: 2rem;
  }

  .contact-info p {
      font-size: 1rem;
  }
}
