.portfolio-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center;
}

/* Portfolio Title */
.portfolio-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

/* Portfolio Sections */
.portfolio-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 3rem 5%;
  text-align: left;
}

/* Responsive Layout for Mobile */
@media (max-width: 768px) {
  .portfolio-section {
      flex-direction: column;
      text-align: center;
  }

  .portfolio-text {
      max-width: 100%;
  }

  .portfolio-video {
      max-width: 100%;
  }
}

/* Portfolio Text */
.portfolio-text {
  flex: 1;
  max-width: 50%;
}

.portfolio-text h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.portfolio-text p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Portfolio Video */
.portfolio-video {
  flex: 1;
  max-width: 50%;
  overflow: hidden;
}

.project-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Separator Line */
.separator {
  width: 80%;
  height: 2px;
  background: #ddd;
  margin: 2rem auto;
}
