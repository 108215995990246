.responses-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.responses-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.responses-table th,
.responses-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.responses-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.responses-table td {
  vertical-align: top;
}

.chart-container {
  margin: 20px auto;
  max-width: 500px;
  height: 400px; /* Explicit height for charts */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

.pie-chart {
  width: 100%;
  height: 100%;
  max-width: 400px; /* Limit the chart size */
  max-height: 300px;
  position: relative;
}
