/* Footer Styles */

.footer {
    background-color: #222;
    color: white;
    padding: 20px 0;
    text-align: center;
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer p {
    font-size: 1rem;
    margin-bottom: 10px;
}

.footer-links {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
}

.footer-links li {
    display: inline;
}

.footer-links a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #4caf50;
}
