.survey-list-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
  text-align: center;
}

/* Hero Video Section */
.hero-video-section {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem; /* Adds spacing below the video */
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.survey-list-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.survey-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.survey-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 1rem;
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s;
}

.survey-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.survey-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #4caf50;
}

.survey-card p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
}

.survey-link {
  display: inline-block;
  background-color: #4caf50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.survey-link:hover {
  background-color: #45a049;
}

/* Responsive Design */
@media (max-width: 768px) {
  .survey-cards {
      flex-direction: column;
      align-items: center;
  }

  .survey-card {
      width: 90%;
  }

  /* Adjust hero section for mobile */
  .hero-video-section {
      height: 300px; /* Slightly smaller video for mobile */
  }
}
