.survey-management {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .survey-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .survey-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .survey-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #4caf50;
  }
  
  .survey-card button {
    margin-top: 10px;
    margin-right: 5px;
    background-color: #4caf50;
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .survey-card button:hover {
    background-color: #45a049;
  }
  
  .back-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #45a049;
  }
  
  .response-list {
    list-style: none;
    padding: 0;
  }
  
  .response-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: left;
  }
  