/* Survey Preview Section */
.survey-preview {
    text-align: center;
    margin-top: 3rem;
    padding: 2rem;
    background-color: #f9f9f9;
}

.survey-preview h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.survey-preview p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1rem;
}

/* Survey Cards */
.survey-cards {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.survey-card {
    background: white;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 1.5rem;
    transition: transform 0.3s ease-in-out;
}

.survey-card:hover {
    transform: translateY(-5px);
}

.survey-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.survey-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
}

/* Centered Button */
.btn-container {
    text-align: center;
    margin-top: 2rem;
}

.btn {
    display: inline-block;
    background: #4caf50;
    color: white;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 6px;
    font-weight: bold;
    transition: background 0.3s ease;
}

.btn:hover {
    background: #45a049;
}

/* Responsive Design */
@media (max-width: 768px) {
    .survey-cards {
        flex-direction: column;
        align-items: center;
    }

    .survey-card {
        width: 90%;
    }
}
