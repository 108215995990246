.login {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .login form {
    display: flex;
    flex-direction: column;
  }
  
  .login input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1rem;
  }
  
  .login button {
    padding: 10px;
    font-size: 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login button:hover {
    background-color: #45a049;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  