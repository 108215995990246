.about-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 1rem;
    text-align: center;
}

/* Hero Video Section */
.hero-video-section {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

/* Company Description (Grey Background) */
.company-description {
    background-color: #f4f4f4;
    padding: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.company-description h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.company-description p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
}

/* Workflow Section with Zebra Pattern */
.workflow-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.workflow-step {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    width: 100%;
    transition: transform 0.2s ease-in-out;
}

.workflow-step:hover {
    transform: translateY(-5px);
}

.grey-bg {
    background-color: #f4f4f4;
}

.icon-container {
    font-size: 3rem;
    color: #4caf50;
}

.text-container {
    max-width: 600px;
}

.text-container h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.text-container p {
    font-size: 1rem;
    color: #666;
}

/* Meet the Team */
.team-section {
    margin-top: 3rem;
}

.team-section h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.team-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
}

.team-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 280px;
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.team-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.team-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.team-card h4 {
    font-size: 1rem;
    color: #4caf50;
    margin-bottom: 0.5rem;
}

.team-card p {
    font-size: 1rem;
    color: #666;
}
